import styled from 'styled-components'

import { colors, fontSizes } from '@/styles'

const TextButton = styled.button`
  color: ${colors.link};
  background: transparent;
  border: none;
  font-size: ${fontSizes.small};
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:not(:disabled) {
    :hover {
      opacity: 0.7;
    }
  }
`

export default TextButton
