import styled from 'styled-components'

const Video = styled.iframe`
  width: 640px;
  height: 360px;
  object-fit: cover;
  object-position: left top;
  display: block;
  max-width: 100%;
  max-height: 100%;
  background-color: black;
`

const VideoPlayer = ({ location, autoPlay, title = 'Playback' }) => {
  // We can include this so that the YouTube player
  // can rotate on mobile if needed
  const allow = ['gyroscope'].join('; ')
  const src = location + (autoPlay ? '?autoplay=1' : '')
  return (
    <Video
      src={src}
      frameBorder="0"
      allowFullScreen
      allow={allow}
      title={title}
    />
  )
}

export default VideoPlayer
