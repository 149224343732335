import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { breakpoints, zIndex } from '@/styles'
import useCurrentSession from '@/hooks/use-current-session'
import useMediaQuery from '@/hooks/use-media-query'
import useSessionComplete from '@/hooks/use-session-complete'

import BaseHeader from './base'
import DefaultHeader from './default-header'

const Menu = styled.nav`
  z-index: ${zIndex.dropdownMenu};
`

export type HeaderPosition = 'fixed' | 'sticky' | 'static'
export enum LogoStyle {
  WHITE = 'white',
  BLACK = 'black'
}

export enum HeaderNavStyle {
  WHITE = 'white',
  BLACK = 'black'
}

export enum HeaderTheme {
  TRANSPARENT = 'transparent',
  BORDERED = 'bordered',
  DEFAULT = 'default'
}

export interface HeaderProps {
  nav?: boolean
  children?: React.ReactNode
  theme?: HeaderTheme
  visible?: boolean
  showTranslate?: boolean
  position?: HeaderPosition
  banner?: React.ReactNode
  logoStyle?: LogoStyle
  headerNavStyle?: HeaderNavStyle
  showSearch?: boolean
  showNavItems?: boolean
  header?: React.ComponentType
  hideCity?: boolean
}

const Header: React.FC<HeaderProps> = ({
  children,
  theme,
  visible,
  nav = true,
  header: HeaderComponent = DefaultHeader,
  ...rest
}) => {
  const session = useCurrentSession()
  const hasSession = useSessionComplete()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const isTransparent = theme === HeaderTheme.TRANSPARENT
  const [forceTheme, setForceTheme] = useState<HeaderTheme | null>(null)

  const pastOffset = useCallback(() => {
    const offset = displayMobile ? 5 : 60
    const doc = document?.documentElement
    return doc ? doc.scrollTop > offset : true
  }, [displayMobile])

  useEffect(() => {
    const scrolledPastOffset = pastOffset()
    setForceTheme(() =>
      isTransparent && scrolledPastOffset ? HeaderTheme.DEFAULT : null
    )
    return () => setForceTheme(null)
  }, [setForceTheme, isTransparent, pastOffset])

  useEffect(() => {
    if (typeof document === 'undefined' || !isTransparent) return
    const handleScroll = () => {
      const scrolledPastOffset = pastOffset()
      if (scrolledPastOffset && forceTheme !== HeaderTheme.DEFAULT) {
        setForceTheme(HeaderTheme.DEFAULT)
      }
      if (!scrolledPastOffset && forceTheme !== HeaderTheme.TRANSPARENT) {
        setForceTheme(HeaderTheme.TRANSPARENT)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [forceTheme, pastOffset, isTransparent])

  if (visible === false) return null

  const overridenTheme = forceTheme || theme

  return (
    <>
      <BaseHeader {...rest} theme={overridenTheme}>
        <HeaderComponent
          hasSession={hasSession}
          session={session}
          nav={nav}
          {...rest}
        />
        {children}
      </BaseHeader>
      <Menu id="header-menu-popper"></Menu>
    </>
  )
}

export default Header
