import dynamic from 'next/dynamic'

const CitySelector = dynamic(() => import('@/features/CitySelector'))

type Props = {
  hideCity?: boolean
}

const CitySelectorHeader: React.FC<Props> = ({ hideCity }) => {
  return <CitySelector instanceId="header-select-city" hideCity={hideCity} />
}

export default CitySelectorHeader
