import { forwardRef, Ref } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import ENV_VARS from '@/config'
import {
  ArrowRightIconBlue,
  CalendarIconBlue24,
  MailIconBlue24
} from '@/components/icons'

import {
  LandlordsDropdownCard,
  NavDropdownLabel,
  NavDropdownLink,
  NavLinkColumn,
  NavLinkDropdownContainer,
  RentersDropdownCard,
  ResourcesDropdownCard,
  ResourcesNavFooter,
  ResourcesNavFooterLink,
  SeeAllFeaturesLink
} from './nav-components'

const { DASHBOARD_URL } = ENV_VARS

const LandlordsDropdown = forwardRef((_: unknown, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation()

  return (
    <LandlordsDropdownCard ref={ref}>
      <NavLinkDropdownContainer>
        <NavLinkColumn>
          <NavDropdownLabel>{t('Features', 'Features')}</NavDropdownLabel>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/screening/"
            id="header-landlords-screening"
          >
            {t('Screening', 'Screening')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/marketing/"
            id="header-landlords-marketing"
          >
            {t('Marketing', 'Marketing')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/leases/"
            id="header-landlords-lease"
          >
            {t('Leases', 'Leases')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/automation/"
            id="header-landlords-automation"
          >
            {t('Automation', 'Automation')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/manage/"
            id="header-landlords-management"
          >
            {t('Management', 'Management')}
          </NavDropdownLink>
          <NavDropdownLink
            href="/landlords/sponsored-listings"
            id="header-landlords-sponsored-listings"
          >
            {t('Sponsored Listings', 'Sponsored Listings')}
          </NavDropdownLink>
          <Link href="/landlords" passHref legacyBehavior>
            <SeeAllFeaturesLink id="header-landlords-see-all-features-btn">
              {t('See all features', 'See all features')}
              <ArrowRightIconBlue width={12} height={12} />
            </SeeAllFeaturesLink>
          </Link>
        </NavLinkColumn>
        <NavLinkColumn>
          <NavDropdownLabel>{t('Use case', 'Use case')}</NavDropdownLabel>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/landlords/"
            id="header-landlords-usecase-landlords"
          >
            {t('Landlords', 'Landlords')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/propertymanagers/"
            id="header-landlords-usecase-pms"
          >
            {t('Property Managers', 'Property Managers')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://landlords.liv.rent/brokerages/"
            id="header-landlords-usecase-brokerages"
          >
            {t('Brokerages', 'Brokerages')}
          </NavDropdownLink>
        </NavLinkColumn>
      </NavLinkDropdownContainer>
    </LandlordsDropdownCard>
  )
})

LandlordsDropdown.displayName = 'LandlordsDropdown'

const RentersDropdown = forwardRef((_: unknown, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation()

  return (
    <RentersDropdownCard ref={ref}>
      <NavLinkDropdownContainer>
        <NavLinkColumn>
          <NavDropdownLabel>{t('Features', 'Features')}</NavDropdownLabel>
          <NavDropdownLink
            href="/rental-listings"
            id="header-renters-browse-listings"
          >
            {t('Browse Listings', 'Browse Listings')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://renters.liv.rent/verified/"
            id="header-renters-verified-listings"
          >
            {t('Verified Listings', 'Verified Listings')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://renters.liv.rent/automation/"
            id="header-renters-automation"
          >
            {t('Automation', 'Automation')}
          </NavDropdownLink>
          <NavDropdownLink
            target="_blank"
            href="https://renters.liv.rent/bitcoin/"
            id="header-renters-bitcoin-payment"
          >
            {t('Bitcoin Payments', 'Bitcoin Payments')}
          </NavDropdownLink>
          <Link href="/renters" passHref legacyBehavior>
            <SeeAllFeaturesLink
              href="/renters"
              id="header-renters-see-all-features-btn"
            >
              {t('See all features', 'See all features')}
              <ArrowRightIconBlue width={12} height={12} />
            </SeeAllFeaturesLink>
          </Link>
        </NavLinkColumn>
      </NavLinkDropdownContainer>
    </RentersDropdownCard>
  )
})

RentersDropdown.displayName = 'RentersDropdown'

const ResourcesDropdown = forwardRef(
  ({ hasSession }: { hasSession: boolean }, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation()

    return (
      <ResourcesDropdownCard ref={ref}>
        <NavLinkDropdownContainer>
          <NavLinkColumn>
            <NavDropdownLabel>
              {t('Rent Reports', 'Rent Reports')}
            </NavDropdownLabel>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/rent-reports/vancouver/"
              id="header-resources-van-rentreport"
            >
              {t('Vancouver', 'Vancouver')}
            </NavDropdownLink>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/rent-reports/toronto/"
              id="header-resources-to-rentreport"
            >
              {t('Toronto', 'Toronto')}
            </NavDropdownLink>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/rent-reports/montreal/"
              id="header-resources-mtl-rentreport"
            >
              {t('Montreal', 'Montreal')}
            </NavDropdownLink>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/rent-reports/alberta/"
              id="header-resources-ab-rentreport"
            >
              {t('Alberta', 'Alberta')}
            </NavDropdownLink>
          </NavLinkColumn>
          <NavLinkColumn>
            <NavDropdownLabel>{t('Learn', 'Learn')}</NavDropdownLabel>
            <Link href="/about" passHref legacyBehavior>
              <NavDropdownLink id="header-resources-about">
                {t('About', 'About')}
              </NavDropdownLink>
            </Link>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/renters/"
              id="header-resources-renter-resources"
            >
              {t('Renter Resources', 'Renter Resources')}
            </NavDropdownLink>
            <NavDropdownLink
              target="_blank"
              href="https://liv.rent/blog/landlords/"
              id="header-resources-landlord-resources"
            >
              {t('Landlord Resources', 'Landlord Resources')}
            </NavDropdownLink>
            <Link href="/faq" passHref legacyBehavior>
              <NavDropdownLink id="header-resources-faq">
                {t('FAQ', 'FAQ')}
              </NavDropdownLink>
            </Link>
          </NavLinkColumn>
        </NavLinkDropdownContainer>
        <ResourcesNavFooter>
          <ResourcesNavFooterLink
            target="_blank"
            href="https://calendly.com/livrent/30min-educational-session"
            id="header-resources-book-a-demo-btn"
          >
            <CalendarIconBlue24 width={13} height={13} />
            {t('Book a demo', 'Book a demo')}
          </ResourcesNavFooterLink>
          <ResourcesNavFooterLink
            target={hasSession ? `_blank` : '_self'}
            href={
              hasSession
                ? `${DASHBOARD_URL}/chat/support-room`
                : 'mailto: support@liv.rent'
            }
            id="header-resources-contact-support-btn"
          >
            <MailIconBlue24 width={13} height={13} />
            {t('Contact support', 'Contact support')}
          </ResourcesNavFooterLink>
        </ResourcesNavFooter>
      </ResourcesDropdownCard>
    )
  }
)

ResourcesDropdown.displayName = 'ResourcesDropdown'

export { LandlordsDropdown, RentersDropdown, ResourcesDropdown }
