import { Trans, useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { colors } from '@/styles'
import { Button } from '@/components/button'
import { SecurityPlayIcon, ShieldIcon } from '@/components/icons'
import { createModal, useModalize } from '@/components/modalize'
import { show as showVideo } from '@/components/video-light-box'
import { VideoLinkPlayer } from '@/components/video-player'

const Wrapper = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: 'Inter';
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 18px 24px;
  box-shadow: inset 0px -1.5px 0px rgba(0, 0, 0, 0.1);
`

const ModalContent = styled.div`
  overflow: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
`

const ModalSection = styled.div``

const Link = styled.a`
  color: ${colors.link};
  text-decoration: none;
  font-size: inherit;
`

const PlayButton = styled(Button)`
  border: 1.5px solid ${colors.grey88};
  padding: 12px;
  gap: 4px;
`

const SecurityModal = () => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const handleClick = () => {
    close()
    showVideo({
      videoLocation: 'https://www.youtube.com/embed/-VLlzHsXi58',
      controls: true,
      autoPlay: true,
      PlayerComponent: VideoLinkPlayer
    })
  }

  return (
    <Wrapper>
      <ModalHeader>
        <ShieldIcon width={16} />
        {t('b.application.security_modal.title.text', 'You can trust us.')}
      </ModalHeader>
      <ModalContent>
        <ModalSection>
          <Trans t={t} i18nKey="b.application.security_modal.content.text">
            liv.rent is committed to protecting your data, never selling it. All
            documents and personal information are stored securely in
            cloud-based storage and protected by military-grade encryption
            protocols.
          </Trans>
        </ModalSection>
        <ModalSection>
          <Trans
            t={t}
            i18nKey="b.application.security_modal.content.information"
          >
            For more information on how we use {'&'} store information, please
            visit <Link href="https://liv.rent/privacy">liv.rent/privacy</Link>{' '}
            or contact our Privacy Officer at{' '}
            <Link href="mailto:privacy@liv.rent">privacy@liv.rent</Link>.
          </Trans>
        </ModalSection>
        <PlayButton
          theme="default"
          size="small"
          width={130}
          onClick={handleClick}
          id="header-menu-shield-watch-video"
        >
          <SecurityPlayIcon width={11} />
          Watch Video
        </PlayButton>
      </ModalContent>
    </Wrapper>
  )
}

export const showSecurityModal = createModal(SecurityModal, {
  maxWidth: '620px',
  width: '536px',
  minHeight: 'unset',
  closeButtonTop: '24px',
  sheet: true
})
