import styled, { css } from 'styled-components'

import { breakpoints, colors, fontSizes, zIndex } from '@/styles'
import { HeaderNavStyle } from '@/features/Header'

const reducedFontSizeOnBreakpoint = css`
  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 14px;
  }
`

const arrowSvgOnHover = css`
  transition: 0.25s;
  transform: rotate(-180deg);

  path {
    fill: ${colors.grey08};
  }
`

const darkBoldOnHover = css`
  color: ${colors.grey08};
  font-weight: 700;
`

const greyedOutButtonAndSvg = css`
  color: ${colors.grey48};
  transition: 0.1s;

  svg {
    path {
      fill: ${colors.grey48};
    }
  }
`

const lightenedButtonAndSvg = css`
  color: rgba(255, 255, 255, 0.8);
  transition: 0.1s;

  svg {
    path {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
`

const NavDropdownLabel = styled.p`
  background: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  color: rgba(35, 37, 38, 0.5);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  padding: 10px;
  text-decoration: none;
`

const NavDropdownLink = styled.a`
  background: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 20px;
  margin: 0;
  padding: 12px 10px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  :hover {
    background-color: ${colors.grey96};
  }

  ${reducedFontSizeOnBreakpoint}
`

const NavLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 150px;
  }
`

const NavDropdownCard = styled.div`
  position: absolute;
  background-color: ${colors.white};
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 1.5px solid ${colors.grey88};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: ${zIndex.modal};
`

const LandlordsDropdownCard = styled(NavDropdownCard)``
const RentersDropdownCard = styled(NavDropdownCard)``
const ResourcesDropdownCard = styled(NavDropdownCard)``

const NavButton = styled.button<{
  headerNavStyle?: HeaderNavStyle
  isOpen: boolean
}>`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.grey08};
  text-align: center;
  line-height: 125%;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  width: 120px;
  background-color: transparent;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  gap: 7px;

  svg {
    pointer-events: none;
    path {
      fill: ${colors.grey08};
    }
  }

  ${props =>
    props.isOpen &&
    `

      ${darkBoldOnHover}

      svg {
        ${arrowSvgOnHover}
      }
  `}

  &:hover {
    ${darkBoldOnHover}
  }

  ${props =>
    props.headerNavStyle === HeaderNavStyle.WHITE &&
    `
    color: ${colors.white};

    svg {
      path {
        fill: ${colors.white};
      }
    }
  `}
`

const LandlordsNavButton = styled(NavButton)``
const RentersNavButton = styled(NavButton)``
const ResourcesNavButton = styled(NavButton)``

const initiallyGreyOutAllButtonsOnHover = css`
  &:hover ${LandlordsNavButton} {
    ${greyedOutButtonAndSvg}
  }

  &:hover ${RentersNavButton} {
    ${greyedOutButtonAndSvg}
  }

  &:hover ${ResourcesNavButton} {
    ${greyedOutButtonAndSvg}
  }
`

const NavButtonsGrouped = styled.div<{
  headerNavStyle?: HeaderNavStyle
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;

  ${initiallyGreyOutAllButtonsOnHover};

  ${props =>
    props.headerNavStyle === HeaderNavStyle.WHITE &&
    `
    &:hover ${LandlordsNavButton} {
      ${lightenedButtonAndSvg}
    }
  
    &:hover ${RentersNavButton} {
      ${lightenedButtonAndSvg}
    }
  
    &:hover ${ResourcesNavButton} {
      ${lightenedButtonAndSvg}
    }
  `}
`

const LandlordsNavOuterDiv = styled.div<{
  headerNavStyle?: HeaderNavStyle
  isOpen: boolean
}>`
  &:hover ${LandlordsNavButton} {
    ${darkBoldOnHover}
  }

  ${props =>
    props.isOpen &&
    `
      ${LandlordsNavButton} {
        ${darkBoldOnHover}
      }
  `}

  ${props =>
    props.headerNavStyle === HeaderNavStyle.WHITE &&
    `
    &:hover ${LandlordsNavButton} {
      color: ${colors.white};
      font-weight: 700;
  
      svg {
        transition: 0.25s;
        transform: rotate(-180deg);
    
        path {
          fill: ${colors.white};
        }
      }
    }
  `}
`

const RentersNavOuterDiv = styled.div<{
  headerNavStyle?: HeaderNavStyle
  isOpen: boolean
}>`
  &:hover ${RentersNavButton} {
    ${darkBoldOnHover}
  }

  ${props =>
    props.isOpen &&
    `
      ${RentersNavButton} {
        ${darkBoldOnHover}
      }
  `}

  ${props =>
    props.headerNavStyle === HeaderNavStyle.WHITE &&
    `
    &:hover ${RentersNavButton} {
      color: ${colors.white};
      font-weight: 700;
  
      svg {
        transition: 0.25s;
        transform: rotate(-180deg);
    
        path {
          fill: ${colors.white};
        }
      }
    }
  `}
`

const ResourcesNavOuterDiv = styled.div<{
  headerNavStyle?: HeaderNavStyle
  isOpen: boolean
}>`
  &:hover ${ResourcesNavButton} {
    ${darkBoldOnHover}
  }

  ${props =>
    props.isOpen &&
    `
      ${ResourcesNavButton} {
        ${darkBoldOnHover}
      }
  `}

  ${props =>
    props.headerNavStyle === HeaderNavStyle.WHITE &&
    `
    &:hover ${ResourcesNavButton} {
      color: ${colors.white};
      font-weight: 700;
  
      svg {
        transition: 0.25s;
        transform: rotate(-180deg);
    
        path {
          fill: ${colors.white};
        }
      }
    }
  `}
`

const NavLinkDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px 0;
  gap: 12px;
`

const SeeAllFeaturesLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.link};
  gap: 10px;
  text-decoration: none;
  background: none;
  font-weight: 600;
  font-size: ${fontSizes.regular};
  margin: 12px 0 32px 10px;

  ${reducedFontSizeOnBreakpoint}
`

const ResourcesNavFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 10px 32px;
  margin-top: 32px;
  background-color: ${colors.grey96};
  border-top: 1.5px solid ${colors.grey88};
  border-radius: 0 0 16px 16px;
`

const ResourcesNavFooterLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.grey24};
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  background: none;
  gap: 10px;
  padding: 5px 0;
`

export {
  NavDropdownLabel,
  LandlordsNavOuterDiv,
  RentersNavOuterDiv,
  ResourcesNavOuterDiv,
  LandlordsNavButton,
  RentersNavButton,
  ResourcesNavButton,
  NavDropdownLink,
  NavButtonsGrouped,
  NavLinkColumn,
  LandlordsDropdownCard,
  RentersDropdownCard,
  ResourcesDropdownCard,
  NavLinkDropdownContainer,
  SeeAllFeaturesLink,
  ResourcesNavFooter,
  ResourcesNavFooterLink
}
