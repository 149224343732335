import Link from 'next/link'
import styled from 'styled-components'

import { breakpoints, colors, zIndex } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { TextButton } from '@/components/button'
import { ShieldIcon } from '@/components/icons'

import { LogoStyle } from '..'
import { showSecurityModal } from '../default-header/security-modal'
import { ReactComponent as Liv } from './liv.svg'
import { ReactComponent as LogoGeneric } from './logo_generic.svg'

const IconWrapper = styled.div`
  display: flex;
  gap: 13px;
  height: 44px;
  width: 87px;

  svg {
    height: 40px;
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    width: 53px;
    height: 28px;
    gap: 8px;

    svg {
      height: 24px;
    }
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    width: 53px;
    height: 28px;
    gap: 8px;

    svg {
      height: 24px;
    }
  }
`

const ShieldWrapper = styled(TextButton)`
  display: flex;
  align-items: center;
  padding: 0;
  z-index: ${zIndex.siteHeader};
  margin-left: ${props => (props.isMobile ? '10px' : '20px')};

  svg {
    pointer-events: none;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LeftSideOfHeader = ({ t, logoColor }) => {
  const displayMobileTabletLandscape = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )

  return (
    <Container>
      <Link
        href="/"
        aria-label={t('b.landing.home.label', 'liv.rent Homepage')}
        id="header-home"
      >
        <IconWrapper>
          <Liv originalSize fill={colors.white} />
          {logoColor === LogoStyle.WHITE ? (
            <LogoGeneric originalSize fill={colors.white} />
          ) : (
            <LogoGeneric originalSize fill={colors.regular} />
          )}
        </IconWrapper>
      </Link>
      <ShieldWrapper
        onClick={e => {
          e.stopPropagation()
          showSecurityModal({})
        }}
        isMobile={displayMobileTabletLandscape}
        id="header-menu-shield-click"
        title="Open Privacy Modal"
        aria-label="Open Privacy Modal"
      >
        <ShieldIcon width={displayMobileTabletLandscape ? 15 : 25} />
      </ShieldWrapper>
    </Container>
  )
}

export default LeftSideOfHeader
