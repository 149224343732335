import React, { forwardRef, Ref, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled, { createGlobalStyle } from 'styled-components'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import structure from '@/structure'
import { breakpoints, colors, fontWeights, zIndex } from '@/styles'
import { toUnit } from '@/utils/styles'
import useLanguage from '@/hooks/use-language'
import useSessionComplete from '@/hooks/use-session-complete'
import { ArrowDown, ArrowDownIconGrey08 } from '@/components/icons'
import useEventListener from '@/components/use-event-listener'
import useMediaQuery from '@/components/use-media-query'
import useOnClickOutside from '@/components/use-onclick-outside'

import { HeaderPosition, HeaderProps, HeaderTheme, LogoStyle } from '..'
import {
  LandlordsDropdown,
  RentersDropdown,
  ResourcesDropdown
} from '../nav-items'
import {
  LandlordsNavButton,
  LandlordsNavOuterDiv,
  NavButtonsGrouped,
  RentersNavButton,
  RentersNavOuterDiv,
  ResourcesNavButton,
  ResourcesNavOuterDiv
} from '../nav-items/nav-components'
import LeftSideOfHeader from './left-side-of-header'

const HtmlWidth = createGlobalStyle`
  html {
    width: 100%;
  }
`

const HeaderContainer = styled.div<{ position?: HeaderPosition }>`
  position: ${props => props.position};
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: ${zIndex.siteHeader};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Wrapper = styled.header<{ logoStyle?: LogoStyle; theme?: HeaderTheme }>`
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) 0
    env(safe-area-inset-left, 0px);
  background-color: ${colors.white};
  height: ${structure.header.height}px;
  ${props =>
    props.theme === HeaderTheme.TRANSPARENT &&
    `
    background-color: transparent;
    border: none;
  `}

  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

  ${props =>
    props.theme === HeaderTheme.BORDERED &&
    `
    border-bottom: 1px solid ${colors.grey};
  `}

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    height: ${structure.header.heightMobile}px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  height: ${toUnit(structure.header.height)};
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @media (max-width: ${breakpoints.desktop}) {
    width: auto;
    padding: 0 20px;
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    height: ${toUnit(structure.header.heightMobile)};
    padding: 0 16px;
  }
`

const LogoGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 140px;

  @media (max-width: 1030px) {
    flex: 0 0 200px;
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    flex: 0.25 0 105px;
  }

  @media (max-width: 900px) {
    flex: 0 0 105px;
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    flex: 1 0 125px;
  }

  @media (max-width: 350px) {
    flex: 1 0 105px;
  }
`

const LanguageLink = styled.button`
  color: ${colors.regular};
  border: none;
  border-left: 1px solid ${colors.regular};
  height: 40px;
  margin: auto 0 auto 25px;
  padding: 7px 0 7px 20px;
  cursor: pointer;
  background: transparent;

  :hover {
    color: rgba(48, 48, 48, 0.3);
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 12px;
    margin-left: 12px;
  }
`

const MapViewButton = styled.button<{ show: boolean | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  gap: 10px;
  font-weight: ${fontWeights.medium};
  background-color: ${colors.grey96};
  transition: all 0.3s ease-in-out;
  position: absolute;
  opacity: 0;
  pointer-events: none;

  ${props =>
    props.show &&
    `
    opacity: 1;
    pointer-events: auto;
  `}

  svg {
    pointer-events: none;
    height: 8px;
    width: 12px;
  }
`

const HeaderComponent = forwardRef(
  (
    {
      position,
      banner,
      theme,
      logoStyle,
      headerNavStyle,
      showTranslate,
      showNavItems,
      children,
      ...rest
    }: HeaderProps,
    ref?: Ref<HTMLElement>
  ) => {
    const { t } = useTranslation('translation')
    const hasSession = useSessionComplete()
    const { language, changeLanguage } = useLanguage()
    const isEnglish = language.value === 'en'
    const [logoColor, setLogoColor] = useState(logoStyle)
    const displayMobile = useMediaQuery(
      `(max-width: ${breakpoints.ipadMiniMax})`
    )
    const aboveIpadMiniMax = useMediaQuery(
      `(min-width: ${breakpoints.ipadMiniMax})`
    )
    const offset = displayMobile ? 5 : 60

    const pastOffset = () => window.pageYOffset > offset

    const onScroll = useCallback(() => {
      pastOffset() ? setLogoColor(LogoStyle.BLACK) : setLogoColor(logoStyle)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pastOffset])

    const dispatch = useDispatch()
    const showMapButton: boolean | undefined = useSelector(
      path(['maplist', 'showMapButton'])
    )

    const handleToggleMapView = () => {
      dispatch.maplist.hideMapButton()
      window.dataLayer.push({
        event: 'list-switch-map-view-click'
      })
    }

    useEventListener('scroll', onScroll, {
      enabled: true
    })

    const [openCard, setOpenCard] = useState('')
    const handleOpen = (id: string) => {
      setOpenCard(id)
    }

    const handleClose = () => {
      setOpenCard('')
    }

    const landlordsRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(landlordsRef, handleClose)
    const rentersRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(rentersRef, handleClose)
    const resourcesRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(resourcesRef, handleClose)

    return (
      <HeaderContainer position={position}>
        {banner}
        <Wrapper theme={theme} logoStyle={logoStyle} {...rest} ref={ref}>
          <InnerWrapper className="inner-wrapper">
            <MapViewButton
              onClick={handleToggleMapView}
              show={showMapButton}
              id="list-switch-map-view-click"
            >
              <ArrowDown />
              {t('b.listings.view.map_view.button', 'Map View')}
            </MapViewButton>
            {!showMapButton && (
              <LogoGroup>
                <LeftSideOfHeader t={t} logoColor={logoColor} />
                {aboveIpadMiniMax && showNavItems && (
                  <NavButtonsGrouped headerNavStyle={headerNavStyle}>
                    <LandlordsNavOuterDiv
                      headerNavStyle={headerNavStyle}
                      isOpen={openCard === 'landlords'}
                    >
                      <LandlordsNavButton
                        headerNavStyle={headerNavStyle}
                        onClick={() => handleOpen('landlords')}
                        isOpen={openCard === 'landlords'}
                        id="header-landlords-main"
                      >
                        {t('Landlords', 'Landlords')}
                        <ArrowDownIconGrey08 width={10} height={10} />
                      </LandlordsNavButton>
                      {openCard === 'landlords' && (
                        <LandlordsDropdown ref={landlordsRef} />
                      )}
                    </LandlordsNavOuterDiv>
                    <RentersNavOuterDiv
                      headerNavStyle={headerNavStyle}
                      isOpen={openCard === 'renters'}
                    >
                      <RentersNavButton
                        headerNavStyle={headerNavStyle}
                        onClick={() => handleOpen('renters')}
                        isOpen={openCard === 'renters'}
                        id="header-renters-main"
                      >
                        {t('Renters', 'Renters')}
                        <ArrowDownIconGrey08 width={10} height={10} />
                      </RentersNavButton>
                      {openCard === 'renters' && (
                        <RentersDropdown ref={rentersRef} />
                      )}
                    </RentersNavOuterDiv>
                    <ResourcesNavOuterDiv
                      headerNavStyle={headerNavStyle}
                      isOpen={openCard === 'resources'}
                    >
                      <ResourcesNavButton
                        headerNavStyle={headerNavStyle}
                        onClick={() => handleOpen('resources')}
                        isOpen={openCard === 'resources'}
                        id="header-resources-main"
                      >
                        {t('Resources', 'Resources')}
                        <ArrowDownIconGrey08 width={10} height={10} />
                      </ResourcesNavButton>
                      {openCard === 'resources' && (
                        <ResourcesDropdown
                          hasSession={hasSession}
                          ref={resourcesRef}
                        />
                      )}
                    </ResourcesNavOuterDiv>
                  </NavButtonsGrouped>
                )}
                {showTranslate && (
                  <LanguageLink
                    onClick={() => changeLanguage(isEnglish ? 'zh' : 'en')}
                  >
                    {isEnglish ? '中文' : 'EN'}
                  </LanguageLink>
                )}
              </LogoGroup>
            )}
            {children}
          </InnerWrapper>
        </Wrapper>
        <HtmlWidth />
      </HeaderContainer>
    )
  }
)

HeaderComponent.displayName = 'HeaderComponent'

const BaseHeader = forwardRef(
  (
    {
      children,
      theme,
      logoStyle,
      showTranslate,
      position = 'sticky',
      banner,
      ...rest
    }: HeaderProps,
    ref?: Ref<HTMLElement>
  ) => {
    return (
      <>
        <HeaderComponent
          ref={ref}
          banner={banner}
          position={position}
          theme={theme}
          logoStyle={logoStyle}
          showTranslate={showTranslate}
          {...rest}
        >
          {children}
        </HeaderComponent>
      </>
    )
  }
)

BaseHeader.displayName = 'BaseHeader'

export default BaseHeader
