import styled, { css } from 'styled-components'

import { breakpoints } from '@/styles'
import { CloseButtonIcon } from '@/components/icons'
import { createModal, useModalize } from '@/components/modalize'
import VideoPlayer from '@/components/video-player'

const CloseButton = styled(CloseButtonIcon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: -23px;
  right: -23px;
  height: 52px;
  width: 52px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    top: -12px;
    right: -12px;
    height: 40px;
    width: 40px;
  }
`

const videoStyles = css`
  height: auto;
  width: unset;
  max-width: 100%;
  max-height: calc(100vh - 40px);
  margin: 0 auto;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-height: calc(100vh - 20px);
  }
`

const VideoLightBox = ({
  videoLocation,
  controls,
  autoPlay,
  PlayerComponent = VideoPlayer
}) => {
  const { close } = useModalize()
  return (
    <>
      <CloseButton onClick={close} />
      <PlayerComponent
        location={videoLocation}
        controls={controls}
        autoPlay={autoPlay}
        customStyle={videoStyles}
      />
    </>
  )
}

export const show = createModal(VideoLightBox, {
  showClose: false,
  width: 'max-content',
  maxWidth: 'calc(100vw - 40px)',
  maxHeight: '100vh',
  radius: 0,
  bgColor: 'rgba(0, 0, 0, 0)',
  overflow: 'visible',
  isVideo: true
})
