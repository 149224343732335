import { MouseEventHandler } from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'

import { CssUnit } from '@/types'
import { colors, fontSizes } from '@/styles'
import { toUnit } from '@/utils/styles'

const DEFAULT_RADIUS = 6

export interface ButtonProps {
  theme?: 'default' | 'primary' | 'secondary' | 'light' | 'neutral'
  type?: 'button' | 'submit' | 'reset' | undefined
  radius?: number
  height?: CssUnit
  width?: CssUnit
  top?: CssUnit
  bottom?: CssUnit
  left?: CssUnit
  right?: CssUnit
  shadow?: boolean
  size?: 'small' | 'medium' | 'large'
  customStyle?: FlattenSimpleInterpolation | undefined
  onClick?: MouseEventHandler<HTMLButtonElement>
  id?: string
  disabled?: boolean
}

const Button = styled.button<ButtonProps>`
  text-decoration: none;
  border-radius: ${props => toUnit(props.radius)};
  height: ${props => toUnit(props.height)};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  font-family: inter, sans-serif;
  width: ${props => toUnit(props.width)};
  min-width: ${props => (props.width !== 'auto' ? 'unset' : '150px')};
  padding: 0 25px;
  margin-top: ${props => toUnit(props.top)};
  margin-bottom: ${props => toUnit(props.bottom)};
  margin-left: ${props => toUnit(props.left)};
  margin-right: ${props => toUnit(props.right)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;

  ${props =>
    props.shadow &&
    `
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  `}

  :active {
    opacity: 0.3;
  }

  &:hover,
  &.selected {
    opacity: 0.9;
  }

  // ACCESSIBILITY FOCUS OUTLINE
  :before {
    content: '';
    position: absolute;
    border: none;
  }
  :focus {
    :before {
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 3px solid ${colors.lightBlue};
      border-radius: ${props => toUnit((props.radius || DEFAULT_RADIUS) + 4)};
    }
  }

  // DEFAULT BUTTON
  ${props =>
    props.theme === 'default' &&
    `
    background-color: ${colors.white};
    border: 2px solid ${colors.regular};
    color: ${colors.regular};
  `}

  // PRIMARY BUTTON
  ${props =>
    props.theme === 'primary' &&
    `
    background-color: ${colors.secondary};
    border: 2px solid ${colors.secondary};
    color: ${colors.white};
  `}

  // SECONDARY BUTTON
  ${props =>
    props.theme === 'secondary' &&
    `
    background-color: ${colors.primary};
    border: 2px solid ${colors.primary};
    color: ${colors.white};
  `}

  // LIGHT BUTTON
  ${props =>
    props.theme === 'light' &&
    `
    background-color: ${colors.white};
    border: 2px solid ${colors.grey};
    color: ${colors.regular};
  `}

  // NEUTRAL BUTTON
  ${props =>
    props.theme === 'neutral' &&
    `
    background-color: ${colors.mediumLightGrey};
    border: 2px solid ${colors.mediumLightGrey};
    color: ${colors.regular};
    font-weight: 600;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  `}

  // BUTTON SIZES
  ${props =>
    props.size === 'large' &&
    `
    font-size: ${fontSizes.regular};
    height: 68px;
    line-height: 22px;
  `}

${props =>
    props.size === 'medium' &&
    `
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    height: 40px;
    min-width: unset;
    border-width: 1px;
    padding: 12px;

    svg {
      margin-right: 8px;
    }
  `}

  ${props =>
    props.size === 'small' &&
    `
    font-size: 12px;
    line-height: 12px;
    height: 40px;
  `}

  // DISABLED STYLES
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed !important;
    opacity: 0.3;

    &:hover,
    &.selected {
      opacity: 0.3;
    }
  `}

  // CUSTOM STYLES
  ${({ customStyle }) => customStyle}
`

Button.defaultProps = {
  theme: 'default',
  type: 'button',
  radius: DEFAULT_RADIUS,
  height: 50,
  width: 'auto',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0'
}

export default Button
