import { useEffect, useRef } from 'react'

interface Options {
  element?: Window | undefined
  enabled?: boolean
}

const useEventListener = <
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  KM extends keyof MediaQueryListEventMap
>(
  eventName: KW | KH | KM,
  handler: (
    event:
      | WindowEventMap[KW]
      | HTMLElementEventMap[KH]
      | MediaQueryListEventMap[KM]
      | Event
  ) => void,
  {
    element = typeof window !== 'undefined' ? window : undefined,
    enabled = true
  }: Options = {}
) => {
  const savedHandler = useRef(handler)
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(() => {
    if (!element || !element.addEventListener || !enabled) return

    const listener: typeof handler = event => savedHandler.current(event)
    element.addEventListener(eventName, listener)
    return () => {
      element.removeEventListener(eventName, listener)
    }
  }, [eventName, element, enabled])
}

export default useEventListener
