import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Userpilot } from 'userpilot'

import { Session } from '@/models/session'
import { breakpoints } from '@/styles'
import useMediaQuery from '@/components/use-media-query'

import { HeaderButton, PostListingButton } from '../header-buttons'
import CitySelector from './city-selector'

const HeaderMenu = dynamic(() => import('../header-menu'), { ssr: false })

const CenterNav = styled.div`
  flex: 1 1 auto;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    width: unset;
  }
`

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const RightNav = styled.nav`
  flex: 1 0 165px;
  min-width: 0;
  @media (max-width: 900px) {
    flex: 1 0 205px;
  }
  @media (max-width: ${breakpoints.ipadMiniMax}) {
    flex: 1 0 165px;
  }
`
const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    gap: 16px;
  }
`

const PricingButton = styled(HeaderButton)<{ showSearch?: boolean }>`
  min-width: 80px;
  flex-shrink: 0;

  ${props => `
    @media (max-width: ${props.showSearch ? breakpoints.v3Desktop : '930px'}) {
      display: none;
    }
  `}
`

interface Props {
  hasSession: boolean
  session: Session
  nav?: boolean
  showSearch?: boolean
  hideCity?: boolean
}

const DefaultHeader: React.FC<Props> = ({
  hasSession,
  nav = true,
  session,
  showSearch,
  hideCity
}) => {
  const avatar = session.avatar?.aws_s3_key

  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    setShowSearchBar(!displayMobile)
  }, [displayMobile])

  const handleClickPricing = () => {
    Userpilot.track('kratos_menu_pricing_link')
  }
  return (
    <>
      {showSearchBar && (
        <CenterNav>
          <CenterContent>
            {nav && showSearch && <CitySelector hideCity={hideCity} />}
          </CenterContent>
        </CenterNav>
      )}
      <RightNav>
        <RightContent>
          <Link href="/pricing" passHref legacyBehavior>
            <PricingButton
              as="a"
              id="menu-pricing-link"
              showSearch={showSearch}
              onClick={handleClickPricing}
            >
              {t('b.header.view.pricing.button', 'Pricing')}
            </PricingButton>
          </Link>
          <PostListingButton />
          <HeaderMenu
            hasSession={hasSession}
            avatarUrl={avatar}
            showSearch={showSearch}
          />
        </RightContent>
      </RightNav>
    </>
  )
}

export default DefaultHeader
