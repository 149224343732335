import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { colors } from '@/styles'
import { PlayIcon } from '@/components/icons'

const VideoItem = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  background-color: ${colors.black};

  ${({ customStyle }) => customStyle}
`

export const VideoPlayButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;

  ${props =>
    props.$disabled &&
    `
    opacity: 0.3;
    cursor: default;
  `}

  ${props =>
    props.$staticButton &&
    `
    right: 0;
    bottom: 0;
    width: 90px;
    height: 94px;
    margin: auto;
  `}
`

const VideoPlayer = ({
  location,
  controls,
  autoPlay,
  loop,
  muted,
  customStyle,
  staticButton,
  disabled,
  playsInLine,
  isLandLord
}) => {
  const video = useRef()
  // We only need to show the play button
  // on desktop Chrome (and variants like Opera).
  // Otherwise the built-in ones like Safari will have 2 play buttons.
  const isChrome = () => {
    if (typeof window === 'undefined') return false
    const nav = window.navigator
    const isChromium = window.chrome
    const vendor = nav.vendor
    const isIOSChrome = nav.userAgent.match('CriOS')
    return isChromium && vendor === 'Google Inc.' && !isIOSChrome
  }

  const [state, setState] = useState(autoPlay ? 'playing' : 'stopped')
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    if (video.current.paused) {
      video.current.play()
      setState('playing')
    } else {
      video.current.pause()
      setState('stopped')
    }
  }

  const startVideo = () => {
    video.current.autoplay = false
    video.current.muted = false
    video.current.loop = false
    video.current.currentTime = 0
    setClicked(true)
  }

  const handleVideo = () => {
    if (state === 'playing') {
      video.current?.pause()
      setState('stopped')
    }
  }

  useEffect(() => {
    const videoRef = video.current
    const resetIt = () => {
      setState('stopped')
    }
    videoRef.addEventListener('ended', resetIt, false)
    return () => videoRef.removeEventListener('ended', resetIt)
  }, [video, setState])

  let playVideoButton = null

  if (
    isLandLord
      ? !clicked || state !== 'playing'
      : state === 'stopped' && isChrome()
  ) {
    playVideoButton = (
      <VideoPlayButton
        $disabled={disabled}
        $staticButton={staticButton}
        onClick={clicked || !isLandLord ? handleClick : startVideo}
      >
        <PlayIcon width={90} height={90} />
      </VideoPlayButton>
    )
  }

  return (
    <>
      <VideoItem
        playsInline={playsInLine}
        ref={video}
        controls={controls}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        customStyle={customStyle}
        preload="none"
        onClick={handleVideo}
      >
        {location.map((item, index) => (
          <source key={index} src={item.key} type={item.type} />
        ))}
      </VideoItem>
      {playVideoButton}
    </>
  )
}

export default VideoPlayer
